import React, { useEffect } from 'react';
import './SuccessPage.scss';
import { Button, Result, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { useGetUser } from '../../utils/useGetUser';
import { addDoc, collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase';

const SuccessPage: React.FC = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const paramCheckoutSession: string | null = queryParams.get('checkout_session');
	const { user }: any = useGetUser();
	useEffect(() => {
		if (paramCheckoutSession && user && user.uid) {
			(async () => {
				try {
					const subRef = collection(db, 'Subscriptions');
					const sub = await addDoc(subRef, {
						dateSubscribed: serverTimestamp(),
						breederId: user.uid
					});
					const breederRef = doc(db, 'Breeders', user.uid);
					await updateDoc(breederRef, {
						isSubscribed: true,
						subId: sub.id
					});
					message.success('successfull subscription');
				} catch (error) {
					message.error('something went wrong');
				}
			})();
		}
	}, [paramCheckoutSession, user]);

	return (
		<div className="customStripeSuccessOnboardingPage absolute-centered">
			{/* <Result
				className="customOnboardingResult"
				status="success"
				title="Successfully onboarded"
				subTitle={<span>Breeder successfully setup stripe payout account. If you have any concerns/questions please reach out to <a href="mailto:hello@mybreedersstore.com">hello@mybreedersstore.com</a></span>}
				extra={[
					<Button onClick={() => window.close()} type="primary" key="console">
						Return to My Breeders Store
					</Button>
				]}
			/> */}
			<Result
				className="customOnboardingResult"
				status="success"
				title="Successfully"
				subTitle={
					<span>
						Breeder successfully. If you have any concerns/questions please reach out to{' '}
						<a href="mailto:hello@mybreedersstore.com">hello@mybreedersstore.com</a>
					</span>
				}
				extra={[
					<Button onClick={() => window.close()} type="primary" key="console">
						Return to My Breeders Store
					</Button>
				]}
			/>
		</div>
	);
};

export default SuccessPage;
