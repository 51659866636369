import React from 'react';
import './Landing.scss';
import { Col, Row } from 'antd';
import PublicLayout from '../../layouts/public/PublicLayout';
import { getToken } from '../../utils/authHelpers';
import PrivateLayout from '../../layouts/private/PrivateLayout';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { Link } from 'react-router-dom';
import landingImage from '../../assets/images/landing.png';
import landing_bottom from '../../assets/images/landing_bottom.png';
import appImage from '../../assets/images/appImage.png';
import appStore from '../../assets/images/appStore.png';
import image1 from '../../assets/images/iosImages/image1.png';
import image2 from '../../assets/images/iosImages/image2.png';
import image3 from '../../assets/images/iosImages/image3.png';
import image4 from '../../assets/images/iosImages/image4.png';
const Landing: React.FC = () => {
	const loggedIn: boolean = getToken() !== '';

	const renderLandingPage = () => {
		const neutralStoreFront = (
			<>
				<div style={{ textAlign: 'center', marginTop: '5rem', overflowX: 'hidden', marginBottom: '90px' }}>
					<div
						style={{
							color: '#3C3A3B',
							textAlign: 'center',
							fontFamily: 'Impact',
							fontSize: '70px',
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: 'normal'
						}}
					>
						Find Your Perfect Pedigree
					</div>
					<div
						style={{
							color: '#3C3A3B',
							textAlign: 'center',
							fontFamily: 'Impact',
							fontSize: '70px',
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: 'normal'
						}}
					>
						Pup Today!"
					</div>
				</div>
				<Row justify={'center'} gutter={[24, 24]}>
					<Col xs={24} sm={12} md={12}>
						<div style={{ width: '100%', maxWidth: '580px', margin: 'auto', padding: '1rem' }}>
							<div
								style={{
									color: '#A1C575',
									fontSize: 39,
									fontFamily: 'Impact',
									fontWeight: '400',
									wordWrap: 'break-word',
									marginBottom: '25px'
								}}
							>
								Connecting Dog Lovers
							</div>
							<div
								style={{
									width: '100%',
									color: 'black',
									fontSize: 26,
									fontFamily: 'Roboto',
									fontWeight: '400',
									wordWrap: 'break-word'
								}}
							>
								My breeders store is a platform for dog lovers to find their perfect furry companion.
								The app features a wide selection of pedigree dogs, with detailed information on each
								pup's breed, age, and temperament.
								<br />
								<br />
								Users can easily browse listings, connect with breeders to find their dream dog.
								Breeders can post their litters as well as upcoming litters and studs, can chat live
								directly with users, and share their listings to their social accounts.
							</div>
							<Link
								to={'/register'}
								style={{
									width: '100%',
									height: '80px',
									background: '#A1C575',
									maxWidth: '310px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: '50px'
								}}
							>
								<div
									style={{
										width: '100%',
										textAlign: 'center',
										color: 'white',
										fontSize: 16,
										fontFamily: 'Roboto',
										fontWeight: '700',
										wordWrap: 'break-word'
									}}
								>
									Sign up
								</div>
							</Link>
						</div>
					</Col>
					<Col xs={24} sm={12} md={12}>
						<img style={{ width: '100%', height: '100%' }} src={landingImage} alt="img" />
					</Col>
				</Row>
				<Row
					justify={'center'}
					gutter={[24, 24]}
					style={{ marginTop: '140px', marginBottom: '140px', padding: '2rem' }}
				>
					<img
						style={{
							width: '100%',
							height: '100%',
							borderRadius: 62
						}}
						src={appImage}
						alt="screen shot"
					/>
				</Row>
				<Row justify={'center'} gutter={[24, 24]}>
					<Col xs={24} sm={12} md={12}>
						<img style={{ width: '100%', height: '100%' }} src={landing_bottom} alt="img" />
					</Col>
					<Col xs={24} sm={12} md={12}>
						<div style={{ width: '100%', maxWidth: '580px', margin: 'auto', padding: '1rem' }}>
							<div
								style={{
									color: '#A1C575',
									fontSize: 39,
									fontFamily: 'Impact',
									fontWeight: '400',
									wordWrap: 'break-word',
									marginBottom: '25px'
								}}
							>
								Secure Payments, Peace of Mind
							</div>
							<div
								style={{
									width: '100%',
									color: 'black',
									fontSize: 26,
									fontFamily: 'Roboto',
									fontWeight: '400',
									wordWrap: 'break-word'
								}}
							>
								With My Breeders Store, Breeders are able to create profiles for their own dogs and post
								listings for dogs they are looking to sell, dog lovers can find their perfect match and
								give a loving home to a beautiful pedigree pup!
								<br />
								<br />
								My Breeders Store uses Stripe as a secure payment provider, which ensures that all
								transactions are safe and protected. Stripe is a trusted payment provider that uses
								industry-standard encryption to protect users' financial information. Stripe uses
								transparent information on transaction fees, so users can be informed of any costs
								associated with buying a dog.
							</div>
							<Link
								to={'/register'}
								style={{
									width: '100%',
									height: '80px',
									background: '#A1C575',
									maxWidth: '310px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: '50px'
								}}
							>
								<div
									style={{
										width: '100%',
										textAlign: 'center',
										color: 'white',
										fontSize: 16,
										fontFamily: 'Roboto',
										fontWeight: '700',
										wordWrap: 'break-word'
									}}
								>
									Sign up
								</div>
							</Link>
						</div>
					</Col>
				</Row>
				<Row justify={'center'} gutter={[24, 24]} style={{ marginTop: '140px' }}>
					<Col xs={24} sm={20} md={18}>
						<div style={{ display: 'flex', overflowX: 'auto', gap: '10px' }}>
							<img style={{ width: '100%', height: '100%' }} src={image1} alt="img" />
							<img style={{ width: '100%', height: '100%' }} src={image2} alt="img" />
							<img style={{ width: '100%', height: '100%' }} src={image3} alt="img" />
							<img style={{ width: '100%', height: '100%' }} src={image4} alt="img" />
						</div>
					</Col>
					<Col xs={24} sm={4} md={6} style={{ width: '100%' }}>
						<div
							style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
						>
							<a
								href="https://apps.apple.com/au/app/my-breeders-store/id6457343451"
								target="_blank"
								rel="noreferrer"
								style={{
									width: '100%',
									maxWidth: '273px'
								}}
							>
								<img style={{ width: '100%', height: '100%' }} src={appStore} alt="img" />
							</a>
						</div>
					</Col>
				</Row>
			</>
		);
		if (loggedIn) {
			return (
				<ProtectedRoute>
					<PrivateLayout className="homepage">{neutralStoreFront}</PrivateLayout>
				</ProtectedRoute>
			);
		} else {
			return (
				<PublicLayout navbar className="homepage">
					{neutralStoreFront}
				</PublicLayout>
			);
		}
	};

	return renderLandingPage();
};

export default Landing;
