import React, { useState } from 'react';
import './BreederOnboardingPayoutpage.scss';
import PrivateLayout from '../../layouts/private/PrivateLayout';
import { Button, message } from 'antd';
import axios from 'axios';
import { BEARER, FIREBASE_BASE_URL } from '../../utils/constant';
import { auth, db } from '../../utils/firebase';
import { collection, doc, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { useSubUser } from '../../utils/useSubUser';
import { useNavigate } from 'react-router-dom';

const BreederOnboardingPayoutpage: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { user }: any = useSubUser();
	const navigate = useNavigate();

	const startOnboarding = async () => {
		if (!user || typeof user?.email !== 'string') return;
		setIsLoading(true);
		try {
			const idToken = await auth.currentUser?.getIdTokenResult(true);
			const res = (
				await axios.get(`${FIREBASE_BASE_URL}/stripe/connect/onboarding`, {
					headers: {
						authorization: `${BEARER} ${idToken?.token ?? ''}`
					}
				})
			).data;
			if (res) {
				if (!res.url) return;
				const newWindow = window.open(
					`${res.url}`,
					'_blank',
					`width=600,height=600,left=${(window.innerWidth - 600) / 2},top=${(window.innerHeight - 600) / 2}`
				);
				const checkClosedInterval = setInterval(async () => {
					if (newWindow && newWindow.closed) {
						clearInterval(checkClosedInterval);
						// Perform actions when the window is closed
						console.log('Child window has been closed');
						const stripeRef = collection(db, 'StripeAccounts');
						const q = query(stripeRef, where('email', '==', user.email));
						const snapShot = await getDocs(q);
						let data: any = {};
						snapShot.forEach((element) => {
							data = element.data();
						});
						if (data.id) {
							const userRef = doc(db, 'Breeders', user.uid);
							await updateDoc(userRef, {
								onboarding_registration: true,
								onboardedAt: serverTimestamp()
							});
						}
						message.success('Successfully created');
						setIsLoading(false);
						navigate('/dashboard');
						// updateBreederAccess();
					}
				}, 1000); // Check every 1 second
			}
		} catch (error) {
			setIsLoading(false);
			console.log(error);
			message.error(`Something wen't wrong in creating a breeder payout account.`);
		}
	};
	return (
		<PrivateLayout className="breederOnboardingPayoutpage customLayoutWidth">
			<Button disabled={isLoading} loading={isLoading} onClick={startOnboarding} type="primary">
				{isLoading ? `Processing` : `Setup Payout Account`}
			</Button>
		</PrivateLayout>
	);
};

export default BreederOnboardingPayoutpage;
