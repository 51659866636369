import React, { useEffect, useState } from 'react';
import './PaywallPopup.scss';
import { PopupModel } from '../../models/PopupModel';
import { Button, Modal, Typography } from 'antd';
import registerImg from '../../assets/images/register.png';
import { removeToken } from '../../utils/authHelpers';
import { useGetUser } from '../../utils/useGetUser';
import { SUBSCRIPTION_CHECKOUT_URL } from '../../utils/constant';

interface PaywallPopupProps extends PopupModel {}

const { confirm } = Modal;

const PaywallPopup: React.FC<PaywallPopupProps> = ({ opened, onCancel, onForceCb }) => {
	const { user }: any = useGetUser();
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [paymentCompleted] = useState<boolean>(false);
	const [, setIsTabActive] = useState(true);

	const onCheckout = () => {
		if (!user) return;
		setIsProcessing(true);
		let url = process.env.REACT_APP_STRIPE_SUBSCRIPTION_CHECKOUT_URL
			? process.env.REACT_APP_STRIPE_SUBSCRIPTION_CHECKOUT_URL
			: SUBSCRIPTION_CHECKOUT_URL;
		window.open(`${url}?prefilled_email=${user.email}`, '_blank');
	};

	const onCancelPayment = () => {
		if (!user) return;
		confirm({
			title: `Not interested in signing up?`,
			content: `Did you know you can try it for 30 days before you are charged. `,
			centered: true,
			okText: 'Exit',
			cancelText: 'Back to payment',
			onOk() {
				onCancel();
				removeToken("paywall");
				console.log('OK');
			},
			onCancel() {
				console.log('Cancel');
			}
		});
	};

	useEffect(() => {
		const handleVisibilityChange = () => {
			setIsTabActive(document.visibilityState === 'visible');
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	// useEffect(() => {
	// 	if (!user) return;
	// 	let unsubscribe: any;
	// 	try {
	// 		const breederRef = doc(db, 'Breeders', user?.uid);
	// 		unsubscribe = onSnapshot(breederRef, (snapshot) => {
	// 			const userAuth = snapshot.data();
	// 			if (userAuth?.isSubscribed) {
	// 				setPaymentCompleted(true);
	// 				setIsProcessing(false);
	// 				setUser({ ...user, ...userAuth });
	// 				onForceCb();
	// 				onCancel();
	// 				// message.success(`Successfully verified subscription payment.`);
	// 			}
	// 		});
	// 	} catch (error) {}
	// 	return () => {
	// 		if (typeof unsubscribe === 'function') unsubscribe();
	// 	};
	// 	// eslint-disable-next-line
	// }, [isTabActive, opened]);

	return (
		<Modal
			closable={false}
			className="paywallPopup"
			open={opened}
			onCancel={!paymentCompleted ? onCancelPayment : onCancel}
			footer={null}
			centered
			title={null}
		>
			<div className="paywallPopupContent">
				<img src={registerImg} alt="register wall asset" className="paywallImg" />
				<Typography.Title className="paywallTitleTxt" level={3}>
					Breeder Subscriptions
				</Typography.Title>
				<Typography.Paragraph className="paywallSubTxt">
					Explore breeder's priviledges & market your products. Browse other breeder's collections.
				</Typography.Paragraph>
				<Button
					className="paywallCta"
					disabled={isProcessing}
					loading={isProcessing}
					onClick={onCheckout}
					type="primary"
				>
					{!isProcessing ? `Proceed to checkout` : `Processing payment`}
				</Button>
				{!isProcessing && (
					<Typography.Paragraph className="paywallFooterHelp">
						Already have an active subscription? <a href="mailto:help@mybreedersstore.com">Need Help</a>
					</Typography.Paragraph>
				)}
			</div>
		</Modal>
	);
};

export default PaywallPopup;
